<template>
	<div>
		<div class="container supliers-items">
			<div class="news-item__header">
				<h3>{{ $t("usefulLinks") }}</h3>
			</div>
			<MainSwiper :data="links" :breakpoints="breakpoints">
				<template #default="{ item }">
					<div class="card">
						<img :src="item.image" alt="" />
						<h3>
							<a :href="item.link" target="_blank">
								{{ $t(item.text) }}
							</a>
						</h3>
					</div>
				</template>
			</MainSwiper>
			<div class="swiper-slide__button">
				<div class="swiper-button-prev">
					<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M7 1L1 7L7 13"
							stroke="#8E9195"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</div>
				<div class="swiper-button-next">
					<svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M1 13L7 7L1 1"
							stroke="#8E9195"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import MainSwiper from "../../../ui/MainSwiper.vue";

export default {
	components: {
		MainSwiper,
	},
	data() {
		return {
			breakpoints: {
				1: {
					slidesPerView: 1,
					spaceBetween: 0,
				},
				640: {
					slidesPerView: 2,
					spaceBetween: 4,
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 4,
				},
				1024: {
					slidesPerView: 4,
					spaceBetween: 10,
				},
			},
			links: [
				{
					image: require("@/assets/images/suppliers/pm-gov.svg"),
					link: "https://senat.uz/",
					text: "link_1",
				},
				{
					image: require("@/assets/images/suppliers/uzb-bojxona.webp"),
					link: "https://customs.uz/",
					text: "link_2",
				},
				{
					image: require("@/assets/images/suppliers/pm-gov.svg"),
					link: "https://miit.uz/",
					text: "link_3",
				},
				{
					image: require("@/assets/images/suppliers/uzb-soliq.svg"),
					link: "https://soliq.uz/",
					text: "link_4",
				},
				{
					image: require("@/assets/images/suppliers/pm-gov.svg"),
					link: "https://parliament.gov.uz/",
					text: "link_5",
				},
				{
					image: require("@/assets/images/suppliers/pm-gov.svg"),
					link: "https://www.imv.uz/",
					text: "link_6",
				},
				{
					image: require("@/assets/images/suppliers/pm-gov.svg"),
					link: "https://stat.uz/",
					text: "link_7",
				},
				{
					image: require("@/assets/images/suppliers/pm-gov.svg"),
					link: "https://www.standart.uz/",
					text: "link_8",
				},
			],
		};
	},
};
</script>
<style lang="scss" scoped>
.supliers-items {
	display: flex;
	flex-direction: column;
	gap: 20px;
	overflow-x: hidden;
}
.card {
	border-radius: 4px;
	padding: 5px;
	min-height: 250px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 10px;
	h3 {
		font-size: 16px;
	}
	img {
		height: auto;
		width: 50px;
		margin: 0 auto;
	}
}
@media screen and (max-width: 1024px) {
	.card {
		min-height: 200px;
		gap: 6px;
		img {
			height: auto;
			width: 40px;
			margin: 0 auto;
		}
		h3 {
			font-size: 14px;
		}
	}
}
@media screen and (max-width: 768px) {
	.supliers-items {
		gap: 20px;
	}
	.card {
		min-height: 200px;

		gap: 6px;
		img {
			height: auto;
			width: 30px;
			margin: 0 auto;
		}
		h3 {
			font-size: 12px;
		}
	}
}
.swiper-slide__button {
	position: relative;
	height: auto;
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: -10px;
	margin-bottom: 25px;
	gap: 40px;
	div {
		z-index: 1;
		position: static;
		margin-top: 0;
		margin-bottom: 0;
		height: auto;
		background: #fff;
		height: 40px;
		width: 40px;
		border-radius: 100%;
		box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
	}
}
</style>

