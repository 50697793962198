<template>
	<div class="main">
		<!-- <HomeNewBanner /> -->
		<HomeBanner />
		<HomeServices />
		<!-- Active Lots -->
		<NewHomePurchasingMethods />
		<!-- <HomePurchasingMethods /> -->
		<HomeActiveLots />
		<!-- <Innovation trades /> -->
		<HomeInnovationAndDisabilityActiveLots />
		<!-- <HomeNews /> -->
		<NewHomeNews />
		<HomeVideoInstruction />
		<HomeSuppliers />
		<AppModal ref="myChild" class="pa-0" v-if="false" max-width="800" :closeButton="false">
			<div class="modal-hold-block">
				<div class="modal__holder">
					<div class="text-center">
						<app-text size="18" weight="600" class="mb-20">ЭЪЛОН</app-text>
						<app-text size="18" weight="600"
							>Xurmatli Elektron cooperation portali ishtirokchilari portali!</app-text
						>
					</div>
					<div class="modal-body pt-30 flex-1">
						<app-text size="18" weight="600" class="mb-20">ЭЪЛОН</app-text>
					</div>
					<div class="modal-footer mt-30 text-center">
						<app-button theme="linear" height="40" sides="20" font-size="14" class="font_Msemibold"
							>OK
						</app-button>
					</div>
				</div>
			</div>
		</AppModal>
	</div>
</template>

<script>
import HomeServices from "@/components/pages/home/home-services/homeServices.vue";
import HomeVideoInstruction from "../../components/pages/home/home-video-instruction/HomeVideoInstruction.vue";
import HomeSuppliers from "../../components/pages/home/home-suppliers/Index.vue";
import AppModal from "../../components/shared-components/AppModal";
import AppButton from "../../components/shared-components/AppButton";
import AppText from "../../components/shared-components/AppText";
import NewHomePurchasingMethods from "../../components/pages/home/home-purchasing-methods/NewHomePurchasingMethods.vue";
import NewHomeNews from "../../components/pages/home/home-news/NewHomeNews.vue";
import HomeBanner from "../../components/pages/home/home-new-banner/Index.vue";
import HomeActiveLots from "../../components/pages/home/home-active-lots/index.vue";
import HomeInnovationAndDisabilityActiveLots from "../../components/pages/home/InnovationDisability.vue";

export default {
	components: {
		HomeSuppliers,
		HomeVideoInstruction,
		HomeServices,
		HomeBanner,
		AppModal,
		AppButton,
		AppText,
		NewHomePurchasingMethods,
		NewHomeNews,
		HomeBanner,
		HomeActiveLots,
		HomeInnovationAndDisabilityActiveLots,
	},
};
</script>
<style scoped>
.main {
	background: #f7f9fb;
	display: flex;
	flex-direction: column;
	row-gap: 40px;
}
</style>
